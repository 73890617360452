<template>
  <div>
    <el-row class="title">
      {{ $t('value_map.dealerBaseInfo') }}
    </el-row>
    <el-row
      :gutter="20"
      class="info-row"
    >
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.dealerShortName') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          {{ baseInfo.shortNameCh || '' }}
        </el-button>
      </el-col>
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.groupName') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          {{ baseInfo.parentName || '' }}
        </el-button>
      </el-col>
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.cityLevel') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          {{ baseInfo.cityTier || '' }}
        </el-button>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      class="info-row"
    >
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.shopAge') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          {{ baseInfo.storeAge || '' }}
        </el-button>
      </el-col>
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.scale') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          {{ baseInfo.operationSize || '' }}
        </el-button>
      </el-col>
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.area') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          {{ baseInfo.region || '' }}
        </el-button>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      class="info-row"
    >
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.province') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          <!-- {{ translateProvince(baseInfo.provinceId) }} -->
          {{ baseInfo.provinceName }}
        </el-button>
      </el-col>
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.city') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          <!-- {{ translateCity(baseInfo.cityId) }} -->
          {{ baseInfo.cityName }}
        </el-button>
      </el-col>
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.salesArea') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          {{ baseInfo.salesArea }}
        </el-button>
      </el-col>
    </el-row>

    <el-row
      :gutter="20"
      class="info-row"
    >
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.afterSalesArea') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          <!-- {{ translateProvince(baseInfo.provinceId) }} -->
          {{ baseInfo.afterSalesArea }}
        </el-button>
      </el-col>
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map.dealerAge') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          <!-- {{ translateCity(baseInfo.cityId) }} -->
          {{ baseInfo.dealerAge }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    baseInfo: { type: Object }
  },
  data () {
    return {}
  },
  computed: {
    areaData () {
      return this.$store.state.app.sysArea
    }
  },
  methods: {
    translateProvince (val) {
      if (!val) return ''
      const rs = this.areaData.find(data => {
        return data.id === val
      })
      return rs ? rs.name : ''
    },
    translateCity (val) {
      if (!val || !this.baseInfo.provinceId) return ''
      const provinceId = this.baseInfo.provinceId
      const province = this.areaData.find(data => {
        return data.id === provinceId
      })
      if (province) {
        const rs = province.children.find(data => {
          return data.id === val
        })
        return rs ? rs.name : ''
      } else return ''
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  background: #eee;
  padding: 6px;
  font-size: 12px;
  font-weight: 600;
}
.info-row {
  height: 28px;
  margin: 6px 0;
  .info-items {
    width: 100%;
    height: 28px;
  }
}
.label {
  background: #f5e2cb;
  line-height: 28px;
  padding: 0 4px;
  text-align: center;
}
</style>
